import { Navigate, Route, Routes } from 'react-router-dom';
import './assets/css/style1.css';
// import AuthVerify from './common/AuthVerify';
import Login from './components/authentication/Login';
import Register from './components/authentication/Register';
import DealDetails from './components/sponsor/DealDetails';
import Profile from './components/Profile/Profile';
import Dashboard from './pages/Dashboard';
import 'antd/dist/antd.css';
import './App.css'
import Home from './pages/Home';
import MainDashboard from './pages/MainDashboard';
import InvestorSignup from './components/authentication/InvestorSignup';
import { useEffect, useState } from 'react';
import MainClient from './utilities/MainClient';
import DashboardLayout from './pages/DashboardLayout';
import LinearStepper from './components/newSponsor/LinaerStepper';
import MyInvestor from './components/myInvestor/MyInvestor';
import Deal from './components/Deal/Deal';
import Error from './pages/Error';
import Main from './components/SubscribeUser/Main';
import Investor from './components/Investor/Investor';
import SponsorProfile from './components/SponsorProfile/Profile';
import AdminProfile from './components/AdminProfile/Profile';
import ChangePassword from './components/ChangePassword/ChangePassword';
import PermissionLayout from './pages/PermissionLayout';
import InvestorPermission from './pages/InvestorPermission';
import UserPermission from './pages/UserPermission';
import LinearStepperDetails from './components/newSponsor/LinaerStepperDetails';
import MySponsor from './components/mySponsor/MySponsor';
import DashboardPermission from './components/authentication/DashbaordPdermission';
import AddDealPermission from './components/authentication/AddDealPdermission';
import ForgetPassword from './components/authentication/ForgetPassword';
import ResetPassword from './components/authentication/ResetPassword';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCloseDeal, fetchOpenDeal } from './store/actions/DealAction';
import { fetchInvestors } from './store/actions/investorActions';
import { fetchUser } from './store/actions/UserActions';
import { fetchDirectSponsors, fetchInvitedSponsors } from './store/actions/SponsorAcrions';
import SponsorProfileDetails from './components/SponsorProfileDetails/Profile';

import PublicUrlPage from './pages/PublicUrlPage';
import PublicUrlMain from './pages/PublicUrlMain';
import DealCalculator from './pages/DealCalculator';

function App() {
  const dispatch = useDispatch()
  const [user, setUser] = useState()
  const accessToken = localStorage.getItem('token')
  const logOut = () => {
    localStorage.removeItem('token')
  }
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  if (accessToken) {
    const decodedJwt = parseJwt(accessToken);
    if (decodedJwt.exp * 1000 < Date.now()) {
        logOut();
    }
  }
  const [profileUpdate, setProfileUpdate] = useState(false)
  useEffect(()=>{
    // dispatch(fetchOpenDeal())
    // dispatch(fetchCloseDeal())
    dispatch(fetchInvestors())
    dispatch(fetchUser())
    dispatch(fetchInvitedSponsors())
    dispatch(fetchDirectSponsors())
  },[])
  useEffect(() => {
    const getUserData = () => {
      const fetchClient = new MainClient(`/user/user-details`)
      const response = fetchClient.getAPI()
      response.then(res => {
        console.log('userData', res.data)
        setUser(res.data)
        localStorage.setItem("user_id", res.data.user_id)
      })
    }
    getUserData()
  }, [profileUpdate])
  const UserUpdateHandler = () =>{
    setProfileUpdate(!profileUpdate)
  }
  // console.log('usr', user)
  // redux part
  const openDeals = useSelector(state=>state.deals.openDealData)  
  // console.log('storeDeals', deals)
 
  return (
    <div>
      <Routes>
        {/* <Route path="/" element={<Home accessToken={user} logOut={logOut} />} /> */}
        <Route path="/" element={user ? <Navigate replace to="/dashboard/main" /> : <Login />} />
        <Route path="/login" element={user ? <Navigate replace to="/dashboard/main" /> : <Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/:signuptoken" element={<Register />} />
        <Route path="/investor/sign-up" element={<InvestorSignup />} />
        <Route path="/investor/signup/:signuptoken" element={<InvestorSignup />} />
        <Route path="/forget-password" element={<ForgetPassword/>} />
        <Route path="/password-reset/:uid/:token/" element={<ResetPassword/>} />
        <Route path="/deal-calculator" element={<DealCalculator/>} />
        <Route path="/dashboard" element={ 
          <PermissionLayout user={accessToken} >
            <DashboardLayout user={user} />
          </PermissionLayout>
        }>
          <Route path="main" element={
            <DashboardPermission user={user}>
              <MainDashboard userType={user?.user_type} />
            </DashboardPermission>
          } />
          <Route exact path='add-new-deal' element={
            <AddDealPermission user={user}>
              <LinearStepper />
            </AddDealPermission>
          }
          />
          <Route path='add-new-deal/:id' element={
            <AddDealPermission user={user}>   
              <LinearStepper /> 
            </AddDealPermission>
          } />  
          <Route path='my-investors' element={
            <AddDealPermission user={user}>
              <MyInvestor userType={user?.user_type} />
            </AddDealPermission>
          } />
          <Route path='sponsors' element={
            <AddDealPermission user={user}>
              <MySponsor userType={user?.user_type} />
            </AddDealPermission>
          } />
          <Route exact path='deals' element={
            <AddDealPermission user={user}>
              <Deal />
            </AddDealPermission>
          } />
          <Route path='deals/:id' element={
            <AddDealPermission user={user}>
              <LinearStepper />
            </AddDealPermission>
          } />
          <Route path='subscriptions/:id' element={
            <AddDealPermission user={user}>
              <Main />
            </AddDealPermission>
          } />
          <Route path='investors/:id' element={<Investor />} />
          <Route path='investor-profile' element={
            // <InvestorPermission user={user}>
            <Profile UserUpdateHandler={UserUpdateHandler}/>
            // </InvestorPermission>
          } />
          <Route path='sponsor-profile' element={
            // <UserPermission user={user} userType="sponsor">
            <SponsorProfile UserUpdateHandler={UserUpdateHandler}/>
            // </UserPermission>
          } />
          <Route path='admin-profile' element={
            // <UserPermission user={user} userType="admin">
            <AdminProfile UserUpdateHandler={UserUpdateHandler}/>
            // </UserPermission>
          } />
          <Route path='change-password' element={<ChangePassword />} />
          <Route path="sponsor-details/:id" element={<SponsorProfileDetails />}/>
        </Route>

        <Route path="/:id/:token" element={<PublicUrlMain />} />
        {/* <Route path="/:id/:token" element={user ? <Navigate replace to="/dashboard/main" /> : <PublicUrlMain />} /> */}
        {/* <Route path="/:id/:token" element={<PublicUrlPage />} /> */}


        {/* <Route path="*" element={<Error />}/> */}
        {/* <Route path="/dashboard" element={user?.user_id ? <MainDashboard userType={user?.user_type}/> : <Navigate replace to="/login" />} />
        <Route path="/dashboard/:name" element={user?.user_id ? <Dashboard user={user}/> : <Navigate replace to="/login" />} /> 
        <Route path="/dashboard/:name/:id" element={user?.user_id ? <Dashboard user={user}/> : <Navigate replace to="/login" />} /> */}
        {/* <Route path="/user-profile" element={user ? <Profile /> : <Navigate replace to="/login" />}></Route> */}
      </Routes>
      {/* <AuthVerify logOut={logOut}/> */}
    </div>
  );
}

export default App;
